
interface Project {
  id: number | null;
  name: string;
  description: string;
  url: string;
  is_active?: number;
}

interface Status {
  id: number | null;
  name: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
}

interface Category {
  id: number | null;
  name: string;
  description?: string;
}

import {
  defineComponent,
  onMounted,
  ref,
  Ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import Button from "@/components/Button.vue";
import Title from "@/components/Title.vue";
import Api from "@/services/Api";
import { useLoaderStore } from "@/store/LoaderStore";
import useAlert from "@/composables/Alert";

export default defineComponent({
  name: "Project",
  components: {
    Button,
    Title,
  },
  props:['idProject'],

  setup(props) {
    const router = useRouter();
    const loadSave: Ref<boolean> = ref(false);
    const hasIdProject = ref(props.idProject);
    const loader = useLoaderStore();
    const fieldRequired: Ref<boolean> = ref(false);
    const { showTimeAlert } = useAlert();
    const status: Ref<Status[]> = ref([]);
    const statusOptions: Ref<Status[]> = ref([
      { id: 1, name: "Aberto" },
      { id: 2, name: "Andamento" },
      { id: 3, name: "Fechado" },
    ]);
    const category: Ref<Category[]> = ref([]);
    const categoryOptions: Ref<Category[]> = ref([]);
    const project: Ref<Project> = ref({
      id: null,
      name: "",
      description: "",
      is_active: 1,
      url: "",
    });

    async function createProject(){
      loadSave.value = true;
      if(!verifyRequiredFields()){
        loadSave.value = false;
        fieldRequired.value = true;
        console.log("preencher campos obrigatórios");
        return;
      }
      try{
        const response = await Api.post('createProjects', {project: project.value, status: status.value, category: category.value});
        showTimeAlert(response.message, 'success');
        router.push('/projectsList');
      }catch(e){
        console.log(e);
      }finally{
        loadSave.value = false;
        fieldRequired.value = true;
      }
    }

    async function getProject(){
      loader.open();
     
        try{
          const { data } = await Api.get('getProjectById', { id: hasIdProject.value });
          if(data.length == 0){
            router.push('/404')
            return;
          }
          project.value.name = data.name;
          project.value.url = data.url;
          project.value.description = data.description;
          project.value.is_active = data.is_active;
          if(data.status.length > 0){
            statusOptions.value = data.status;
            status.value = data.status.map((item: Status) => item.name);
          }
          if(data.category.length > 0){
            categoryOptions.value = data.category;
            category.value = data.category.map((item: Category) => item.name);
          }
        }catch(e){
          console.log(e);
        }finally{
          loader.close();
        }
      }

      function verifyRequiredFields(){
        if(project.value.name == "" || status.value.length == 0 || category.value.length == 0){
          return false;
        }
        return true;
      }

    onMounted(() => {
      if(hasIdProject.value){
        project.value.id = hasIdProject.value;
        getProject();
      }
    });

    return {
      hasIdProject,
      router,
      project,
      createProject,
      loadSave,
      status,
      category,
      statusOptions,
      categoryOptions,
      fieldRequired
    };
  },
});
